
module.exports = {


//database:'http://localhost:4000/'
   
database:'https://esme.cuquicalvano.com:4000/'
   
   
   }

